<template>
  <div>
    <v-scale-screen width="1920" height="1080">
      <div
        style="
          display: flex;
          color: white;
          position: absolute;
          font-size: 18px;
          width: 20vw;
          align-items: center;
          z-index: 999;
          right: 0%;
          top: 3%;
        "
      >
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[0] }}</div>
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[1] }}</div>
        <div style="font-size: 22px; margin-right: 5%">{{ time.txt[2] }}</div>
      </div>
      <el-carousel
        indicator-position="outside"
        :interval="10000"
        ref="carousel"
      >
        <el-carousel-item key="0">
          <div class="dashboard-box">
            <img
              src="../assets/img/biaoti.png"
              style="height: 80px; width: 100%"
            />
            <div class="main-body">
              <div class="left-body">
                <el-row>
                  <el-col :span="12">
                    <div class="box-div">
                      <div class="box-title">
                        <span class="title">产品数量</span>
                      </div>
                      <div class="box-area">
                        <div class="area-title">
                          <img src="../assets/img/biaotitu4.png" />产线
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(item, index) in allData.linestatus"
                            :key="index"
                          >
                            <p
                              style="font-weight: bold"
                              :style="{
                                fontSize:
                                  item.dailycapacity >= 100
                                    ? '2vh'
                                    : item.dailycapacity > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.dailycapacity }}
                              <span
                                :style="{
                                  fontSize:
                                    item.dailycapacity < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p
                              :style="{
                                color: item.isrun ? '#76ec2c' : 'red',
                              }"
                            >
                              {{ item.name }}
                            </p>
                          </div>
                        </div>
                        <div class="area-title">
                          <img src="../assets/img/biaotitu1.png" />待补充区
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(
                              item, index
                            ) in allData.areatobesupplemented"
                            :key="index"
                          >
                            <p
                              class="num-y"
                              :style="{
                                fontSize:
                                  item.value >= 100
                                    ? '2vh'
                                    : item.value > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.value }}
                              <span
                                :style="{
                                  fontSize: item.value < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div class="area-title">
                          <img src="../assets/img/biaotitu2.png" />毛胚区
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(
                              item, index
                            ) in allData.pendingprocessingarea"
                            :key="index"
                          >
                            <p
                              class="num-g"
                              :style="{
                                fontSize:
                                  item.value >= 100
                                    ? '2vh'
                                    : item.value > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.value }}
                              <span
                                :style="{
                                  fontSize: item.value < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div class="area-title">
                          <img src="../assets/img/biaotitu3.png" />成品区
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(item, index) in allData.processingarea"
                            :key="index"
                          >
                            <p
                              class="num-b"
                              :style="{
                                fontSize:
                                  item.value >= 100
                                    ? '2vh'
                                    : item.value > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.value }}
                              <span
                                :style="{
                                  fontSize: item.value < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <div class="area-title">
                          <img src="../assets/img/biaotitu4.png" />待检区
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(
                              item, index
                            ) in allData.inspectionwaitingarea"
                            :key="index"
                          >
                            <p
                              class="num-e"
                              :style="{
                                fontSize:
                                  item.value >= 100
                                    ? '2vh'
                                    : item.value > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.value }}
                              <span
                                :style="{
                                  fontSize: item.value < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p>{{ item.name }}</p>
                          </div>
                        </div>
                        <!-- <div class="area-title">
                          <img src="../assets/img/biaotitu4.png" />发货区
                        </div>
                        <div class="box-item">
                          <div
                            class="box-span"
                            v-for="(item, index) in allData.packagingarea"
                            :key="index"
                          >
                            <p
                              style="font-weight: bold"
                              :style="{
                                fontSize:
                                  item.value >= 100
                                    ? '2vh'
                                    : item.value > 1000
                                    ? '1.5vh'
                                    : '3vh',
                              }"
                            >
                              {{ item.value }}
                              <span
                                :style="{
                                  fontSize: item.value < 1000 ? '2vh' : '1.5vh',
                                }"
                                >个</span
                              >
                            </p>
                            <p>{{ item.name }}</p>
                          </div>
                        </div> -->
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="left-num">
                      <div class="box1">
                        <img src="../assets/img/alicon1.png" />
                        <CountTo
                          :endVal="allData.totalordernumber"
                          class="num-y"
                        />
                        <span>生产工单数</span>
                      </div>
                      <div class="box1">
                        <img src="../assets/img/alicon2.png" />
                        <span class="num-y">{{
                          allData.totaldelayordernumber
                        }}</span>
                        <!-- <span class="num-y">0</span> -->
                        <span>当前延期数</span>
                      </div>
                      <div class="box1">
                        <img src="../assets/img/alicon3.png" />
                        <CountTo
                          :endVal="allData.todayproductionquantity"
                          class="num-y"
                        />
                        <span>今日生产数量</span>
                      </div>
                      <div class="box1">
                        <img src="../assets/img/alicon4.png" />
                        <span class="num-r"
                          >{{ allData.todayrejectrate }}%</span
                        >
                        <span>今日报废率</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="right-body">
                <div class="box-title-c">
                  <span class="title">报废分析</span>
                  <span class="title-tip">近10天</span>
                </div>
                <div class="box-area">
                  <div id="echarts-line"></div>
                  <!-- <div id="echarts-pie"></div> -->
                  <el-table :data="sgqyData" style="width: 100%">
                    <el-table-column prop="data" label="日期" align="center">
                    </el-table-column>
                    <el-table-column
                      prop="productname"
                      label="产品名称"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="productnumber"
                      label="产品数量"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="scraprate"
                      label="报废率"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column prop="reason" label="报废原因">
                      <template slot-scope="scope">
                        <p class="wrap-text">{{ scope.row.reason }}</p>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <el-col :span="20">
              <div class="box-title-b">
                <span class="title" style="padding-left: 1.5vw">项目信息</span>
              </div>
              <el-table
                :data="tableData"
                style="width: 100%"
                ref="scrollTable"
                height="250px"
                :row-class-name="tableRowClassName"
                @mouseenter.native="scrollTable(true)"
                @mouseleave.native="scrollTable(false)"
              >
                <el-table-column
                  v-for="(item, key) in tableHead"
                  :key="key"
                  :label="item.label"
                  :prop="item.prop"
                  :width="item.width"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <div v-if="item.prop == 'state'" class="statusbox">
                      {{ row.state }}
                    </div>
                    <div
                      v-else-if="item.prop == 'schedule'"
                      style="display: flex; justify-content: center"
                    >
                      <el-progress
                        style="width: 220px; height: 25px"
                        :text-inside="true"
                        :stroke-width="26"
                        v-if="row.schedule"
                        :percentage="parseInt(row.schedule)"
                        text-color="#fff"
                        define-back-color="rgb(216 217 220 / 24%)"
                      ></el-progress>
                      <!-- <span
                        v-if="row.mpsl >= row.quantity || row.mpsl == 0"
                        :class="row.mpsl >= row.quantity ? 'check' : 'uncheck'"
                        >码盘<i
                          v-show="row.mpsl >= row.quantity"
                          class="el-icon-circle-check"
                        ></i
                      ></span>
                      <el-progress
                        style="width: 100px; height: 25px"
                        v-else
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="row.schedule.mp"
                        :format="formatmp"
                        text-color="#fff"
                        define-back-color="rgb(216 217 220 / 24%)"
                      ></el-progress>
                      -
                      <span
                        v-if="
                          row.productionvolume >= row.quantity ||
                          row.productionvolume == 0
                        "
                        :class="
                          row.productionvolume >= row.quantity
                            ? 'check'
                            : 'uncheck'
                        "
                        >车加工<i
                          v-show="row.productionvolume >= row.quantity"
                          class="el-icon-circle-check"
                        ></i
                      ></span>
                      <el-progress
                        style="width: 100px; height: 25px"
                        v-else
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="row.schedule.sc"
                        :format="format"
                        text-color="#fff"
                        define-back-color="rgb(216 217 220 / 24%)"
                      ></el-progress>
                      -<span
                        v-if="row.jysl >= row.quantity || row.jysl == 0"
                        :class="row.jysl >= row.quantity ? 'check' : 'uncheck'"
                        >检验<i
                          v-show="row.jysl >= row.quantity"
                          class="el-icon-circle-check"
                        ></i
                      ></span>
                      <el-progress
                        style="width: 100px; height: 25px"
                        v-else
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="row.schedule.jy"
                        :format="formatjy"
                        text-color="#fff"
                        define-back-color="rgb(216 217 220 / 24%)"
                      ></el-progress
                      >-
                      <span
                        v-if="row.dbsl >= row.quantity || row.dbsl == 0"
                        :class="row.dbsl >= row.quantity ? 'check' : 'uncheck'"
                        >打包<i
                          v-show="row.dbsl >= row.quantity"
                          class="el-icon-circle-check"
                        ></i
                      ></span>
                      <el-progress
                        style="width: 100px; height: 25px"
                        v-else
                        :text-inside="true"
                        :stroke-width="26"
                        :percentage="row.schedule.jy"
                        :format="formatdb"
                        text-color="#fff"
                        define-back-color="rgb(216 217 220 / 24%)"
                      ></el-progress> -->
                    </div>
                    <span v-else>{{ row[item.prop] }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <div class="footer">
              <div>
                已安全生产<span class="day">{{ today }}</span
                >天
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item key="1">
          <div class="page1"></div>
        </el-carousel-item>
        <el-carousel-item key="2">
          <div class="page2"></div>
        </el-carousel-item>
        <el-carousel-item key="3">
          <div class="page3"></div>
        </el-carousel-item>
      </el-carousel>
    </v-scale-screen>
  </div>
</template>

<script>
import * as echarts from 'echarts' // echarts theme
import axios from 'axios'
import CountTo from '@/components/countTo'
import { formatDate } from '@/utils/formatTime'
require('echarts/theme/macarons')

export default {
  components: { CountTo },
  data () {
    return {
      tableHead: [
        {
          label: '工单编号',
          prop: 'no'
        },
        {
          label: '工单状态',
          prop: 'state'
        },
        {
          label: '整体进度',
          prop: 'schedule'
        },
        {
          label: '产品名称',
          prop: 'productname'
        },
        {
          label: '产品规格',
          prop: 'model'
        },
        {
          label: '工单量',
          prop: 'quantity'
        },
        {
          label: '生产量',
          prop: 'productionvolume'
        }
      ],
      txt: [],
      tableData: [],
      sgqyData: [],
      today: '',
      time: {
        txt: [],
        fun: null
      },
      allData: {
        areatobesupplemented: [],
        pendingprocessingarea: [],
        processingarea: [],
        inspectionwaitingarea: [],
        linestatus: [],
        todayrejectrate: 0,
        todayproductionquantity: 0,
        totaldelayordernumber: 0,
        totalordernumber: 0
      }

    }
  },
  created () {
    this.getData()
    this.initTime()
  },
  filters: {
    // 数字超过100css字体变小
    formatfontsize (time) {
      return time.substring(0, 10)
    }
  },
  mounted () {
    setInterval(() => {
      this.getData()
    }, 300000)
  },
  methods: {
    // delHandleMouseEnter () {
    // this.$refs.carousel.handleMouseEnter = () => { }
    // },
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        return 'warning-row'
      }
      return ''
    },
    format (percentage) {
      return `车加工${percentage}%`
    },
    formatmp (percentage) {
      return `码盘${percentage}%`
    },
    formatjy (percentage) {
      return `检验${percentage}%`
    },
    formatdb (percentage) {
      return `打包${percentage}%`
    },
    // 初始化右上角时间显示
    initTime () {
      this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM WWW').split(' ')
      this.time.fun = setInterval(() => {
        this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM WWW').split(
          ' '
        )
      }, 1000)
    },
    daysSinceStart () {
      const startDate = new Date('2024-01-01') // 定义开始日期
      const currentDate = new Date() // 获取当前日期
      // 计算两个日期之间的差值（以毫秒为单位）
      const timeDifference = currentDate.getTime() - startDate.getTime()
      // 将毫秒转换为天数（1天 = 24小时 × 60分钟 × 60秒 × 1000毫秒）
      const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
      return daysPassed
    },
    // scrollTable (stop) {
    //   var table = this.$refs.scrollTable
    //   var div = table.$refs.bodyWrapper
    //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    //   if (stop) {
    //     // 再通过事件监听，监听到 组件销毁 后，再执行关闭计时器。
    //     window.clearInterval(this.scrollInterval)
    //   } else {
    //     this.scrollInterval = window.setInterval(() => {
    //       // 元素自增距离顶部1像素
    //       div.scrollTop += 1
    //       // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    //       if (div.clientHeight + div.scrollTop >= div.scrollHeight) {
    //         // 重置table距离顶部距离
    //         div.scrollTop = 0
    //       }
    //     }, 400) // 滚动速度
    //   }
    // },
    getData () {
      // axios.get('http://192.168.4.76:8011/api/Statistics/GetIndex').then((res) => {
      axios.get('https://ailun.chuilian-tech.cn/api/Statistics/GetIndex').then((res) => {
        this.allData = res.data.data
        this.setSchedule(res.data.data.productionorderlist)
        this.drawLineChart(res.data.data.zzzxt)
        // this.drawPieChart(res.data.data.bt)
        this.sgqyData = res.data.data.sgqy
        this.today = this.daysSinceStart()
      })
        .catch((err) => {
          console.log(err)
        })
    },
    setSchedule (data) {
      data.forEach(element => {
        // element.schedule = {
        //   mp: 0,
        //   sc: 0,
        //   jy: 0,
        //   db: 0
        // }
        // if (element.mpsl > 0) {
        //   element.schedule.mp = (element.mpsl / element.quantity).toFixed(2) * 100
        // } else {
        //   element.schedule.mp = 0
        // }
        // if (element.productionvolume > 0) {
        //   element.schedule.sc = (element.productionvolume / element.quantity).toFixed(2) * 100
        // } else {
        //   element.schedule.sc = 0
        // }
        // if (element.jysl > 0) {
        //   element.schedule.jy = (element.jysl / element.quantity).toFixed(2) * 100
        // } else {
        //   element.schedule.jy = 0
        // }
        // if (element.dbsl > 0) {
        //   element.schedule.db = (element.dbsl / element.quantity).toFixed(2) * 100
        // } else {
        //   element.schedule.db = 0
        // }
        element.schedule = Math.min(((element.productionvolume / element.quantity) * 100), 100).toFixed(2)
      })
      this.tableData = data
      // this.scrollTable()
    },
    drawLineChart (data) {
      const lineChart = echarts.init(document.getElementById('echarts-line'))
      var series = []
      var legend = []
      data.fplist.forEach(element => {
        series.push({
          name: element.productname,
          type: 'line',
          data: element.scraplist
        })
        legend.push(element.productname)
      })
      data.fpllist.forEach(element => {
        series.push({
          name: element.productname,
          type: 'bar',
          animationDuration: 2000,
          barWidth: 15,
          barGap: '0%',
          itemStyle: {
            color: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              type: 'linear',
              global: false,
              colorStops: [{
                offset: 0, // 0%处的颜色
                color: '#4486F4'
              }, {
                offset: 1, // 100%处的颜色
                color: '#14449E'
              }]
            }
          },
          data: element.scrapratelist
        })
      })
      lineChart.setOption({
        title: {
          text: '报废趋势图',
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: legend,
          textStyle: {
            color: '#FFF'
          },
          top: '9%',
          selectedMode: false
        },
        xAxis: [
          {
            type: 'category',
            data: data.xarray,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              color: '#ffffff'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 20,
            alignTicks: true,
            axisLabel: {
              formatter: '{value} 个',
              color: '#ffffff'
            }
          },
          {
            type: 'value',
            min: 0,
            max: 1,
            alignTicks: true,
            axisLabel: {
              formatter: '{value} %',
              color: '#ffffff'
            }
          }
        ],
        grid: {
          show: true,
          borderColor: '#ccc',
          borderWidth: 0,
          z: 1,
          left: 30,
          right: 30,
          top: 60,
          bottom: 30,
          containLabel: true,
          textStyle: {
            color: '#333',
            fontSize: 12
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: series
        // series: [
        //   {
        //     name: '报废数',
        //     type: 'bar',
        //     animationDuration: 2000,
        //     barWidth: 15,
        //     barGap: '0%',
        //     itemStyle: {
        //       color: {
        //         x: 0,
        //         y: 0,
        //         x2: 0,
        //         y2: 1,
        //         type: 'linear',
        //         global: false,
        //         colorStops: [{
        //           offset: 0, // 0%处的颜色
        //           color: '#4486F4'
        //         }, {
        //           offset: 1, // 100%处的颜色
        //           color: '#14449E'
        //         }]
        //       }
        //     },
        //     data: data.defectiveproductslist
        //   },
        //   {
        //     name: '报废率',
        //     type: 'line',
        //     data: data.rejectratelist
        //   }
        // ]
      })
    },
    drawPieChart (data) {
      const pieChart = echarts.init(document.getElementById('echarts-pie'))
      pieChart.setOption({
        title: {
          text: '报废项分布图',
          left: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['尺寸不符', '尺寸不合格', '外观缺陷'],
          textStyle: {
            color: '#FFF'
          },
          top: '10%'
        },
        series: [
          {
            type: 'pie',
            radius: ['65%', '75%'],
            label: {
              color: '#fff',
              formatter: '{b} {c}个({d}%)'
            },
            top: '12%',
            data: data
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "UnidreamLED";
  src: url("../assets/fontface/ds-digib-webfont.woff") format("woff"),
    local("UnidreamLED"), url("../assets/fontface/ds-digib-webfont.woff"); /***默认使用本地的***/
}
.dashboard-box {
  height: 100vh;
  width: 100vw;
  background: url("../assets/img/aldbbjt.png");
}
.main-body {
  display: flex;
  justify-content: space-between;
}
.left-body {
  width: 65%;
}
.right-body {
  width: 35%;
  padding: 0 10px;
}
.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  background: url("../assets/img/dibubiaoti.png") no-repeat;
  background-position: 50% 85%;
  div {
    color: #fff;
    position: absolute;
    left: 45%;
    bottom: 5%;
    font-size: 1.5rem;
  }
  .day {
    color: #1ce6f4;
  }
}
.statusbox {
  border: 1px solid #76ec2c;
  color: #76ec2c;
  width: 80px;
  margin: 0 auto;
  border-radius: 15px;
}
.check {
  border: 1px solid #fff;
  color: rgb(22, 28, 92);
  background: #fff;
  width: 88px;
  padding: 0 5px;
  border-radius: 15px;
}
.wrap-text {
  white-space: pre-line;
}
.uncheck {
  border: 1px solid #fff;
  color: #fff;
  width: 88px;
  padding: 0 5px;
  border-radius: 15px;
}
.box-title {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  height: 3vh;
  line-height: 3vh;
  background-position: -12% 100%;
  margin-bottom: 0.5vh;
}
.box-area {
  background-color: rgb(22 28 92 / 46%);
  border: 1px solid rgb(68, 85, 107);
  color: #fff;
  padding: 0.5vw;
  .area-title {
    margin: 1% 0;
    display: flex;
    align-content: center;
    img {
      margin-right: 1%;
    }
  }
  .box-item {
    display: flex;
    justify-content: space-between;
    background: url("../assets/img/biankaung1.png") no-repeat;
    background-size: 100% 100%;
    padding: 1% 2%;
  }
  .box-span {
    background: url("../assets/img/biankuang2.png") no-repeat;
    background-size: 100% 100%;
    padding: 10px;
    font-size: 1.5vh;
    text-align: center;
    width: 7vh;
    p:first-child {
      height: 4vh;
      margin-block-start: 0;
      margin-block-end: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin: 0;
    }
  }
}
:deep() .el-carousel__container {
  height: 100vh !important;
}
.box-title-b {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  background-position: -2% 60%;
  margin-bottom: 0.5vh;
}
.page1 {
  background-image: url("../assets/img/page1.png");
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page2 {
  background: url("../assets/img/page2.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.page3 {
  background: url("../assets/img/page3.png") no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
.box-title-c {
  background: url("../assets/img/xiaobiaoti.png") no-repeat;
  color: white;
  background-position: -11% 55%;
  margin-bottom: 0.5vh;
}
.title {
  font-size: 2vh;
  margin-left: 1.5vw;
}
.title-tip {
  font-size: 1rem;
  margin-left: 0.5%;
  color: #1ce6f4;
}
.box1 {
  background: url("../assets/img/biankaung3.png") no-repeat center;
  background-size: 100% 100%;
  background-position: 100% 100%;
  width: 48%;
  min-height: 154px;
  margin: 1%;
  height: 11%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  font-size: 2vh;
  font-family: "UnidreamLED";
  img {
    width: 2vh;
    padding: 1vh;
  }
}
.box-div {
  padding: 0 10px;
}
.left-num {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3vh;
}
.num-b {
  color: #1ce6f4;
  font-size: 5vh;
  font-weight: bold;
}

.num-g {
  color: #76ec2c;
  font-size: 5vh;
  font-weight: bold;
}

.num-r {
  color: #fd0717;
  font-size: 5vh;
  font-weight: bold;
}

.num-y {
  color: #ebb30b;
  font-size: 5vh;
  font-weight: bold;
}
.num-e {
  color: rgb(254, 232, 86);
  font-weight: bold;
}
::v-deep .el-table {
  background-color: transparent;
  color: white;
}
::v-deep .el-table thead {
  color: #1ce6f4 !important;
  background-color: rgb(66 136 232 / 46%);
  margin-bottom: 5px;
}
::v-deep .el-table tr {
  background-color: rgb(22 28 92 / 46%);
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border-top: 1px solid #4288e8;
  border-bottom: 1px solid #4288e8;
}
::v-deep .el-table th > .cell {
  padding-left: 0px;
  padding-right: 0px;
}
::v-deep .el-table::before {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table .cell {
  padding: 2px 0;
}
::v-deep .el-table th {
  padding: 6px 0;
  background-color: transparent;
}
::v-deep.el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table td.el-table__cell {
  border-bottom: none;
}
::v-deep .el-table td {
  padding: 3px 0;
}
#echarts-line {
  height: 30vh;
}
#echarts-pie {
  height: 30vh;
}
::v-deep .warning-row {
  background-color: rgba(1, 2, 14, 0.46) !important;
}
::v-deep .el-table {
  .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // 整个滚动条
      width: 0; // 纵向滚动条的宽度
      background: rgba(213, 215, 220, 0.3);
      border: none;
    }
    &::-webkit-scrollbar-track {
      // 滚动条轨道
      border: none;
    }
  }
  // --------------------隐藏table gutter列和内容区右侧的空白
  th.gutter {
    display: none !important;
    width: 0 !important;
  }
  colgroup col[name="gutter"] {
    display: none;
    width: 0;
  }
  .el-table__body {
    width: 100% !important;
  }
}
</style>
